<template>
    <div>
        <modal class="new-modal-small" :name="modal_name" transition="nice-modal-fade" :delay="100" height="auto" width="500" :min-width="200" :max-width="500" :pivot-y="0.5" :adaptive="true" :scrollable="true">
            <div class="v-modal-content d-flex flex-column justify-content-center align-items-center text-center" style="background-color: #fff !important; height: 10em !important;">
                <!-- <img src="/static/images/delete_pop_icon.svg" style="height: 10rem !important" /> -->
                <div class="mt-5">
                    <div>
                        <span class="fs-15 fw-600">Personal Email ID is duplicated</span>
                    </div>
                </div>
                <div class="d-flex align-items-center justify-content-center mt-6">
                    <button
                        type="button"
                        class="btn btn-new-primary ml-4"
                        style="box-shadow: none;min-width: 5rem;"
                        @click="hideDeleteError"
                    >OK</button>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>

export default ({
    props:['modal_name'],
    data(){
        return{

        }
    },
    methods:{
        hideDeleteError(){
            this.$modal.hide(this.modal_name);
            this.$emit('hideDeleteError');
        },
    }
})

</script>

<style scoped>
    .btn-primary-outline{
        color: #2b6ad0;
		border-radius: 5px;
		padding-left: 35px;
		padding-right: 35px;
		text-transform: none;
		font-weight: 500;
		line-height: 30px;
		box-shadow: none;
		border-color: 2px solid #2b6ad0;
		box-shadow: none !important;
		font-weight: 500;
    }
</style>
